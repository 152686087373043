<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 186 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
  >
    <g transform="matrix(1,0,0,1,-207.426,-345.326)">
      <g transform="matrix(1,0,0,1,-100,0)">
        <g transform="matrix(1,0,0,1,228.403,88.6879)">
          <g transform="matrix(36,0,0,36,75.9273,283.098)">
            <path
              d="M0.086,-0.034C0.086,-0.013 0.103,0.005 0.125,0.005C0.147,0.005 0.163,-0.013 0.163,-0.034L0.163,-0.099C0.203,-0.041 0.265,0.011 0.361,0.011C0.486,0.011 0.608,-0.089 0.608,-0.258L0.608,-0.26C0.608,-0.43 0.485,-0.528 0.361,-0.528C0.266,-0.528 0.205,-0.475 0.163,-0.413L0.163,-0.696C0.163,-0.718 0.146,-0.735 0.124,-0.735C0.103,-0.735 0.086,-0.718 0.086,-0.696L0.086,-0.034ZM0.347,-0.058C0.251,-0.058 0.16,-0.138 0.16,-0.258L0.16,-0.26C0.16,-0.378 0.251,-0.459 0.347,-0.459C0.445,-0.459 0.529,-0.381 0.529,-0.259L0.529,-0.257C0.529,-0.132 0.447,-0.058 0.347,-0.058Z"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
          <g transform="matrix(36,0,0,36,99.0753,283.098)">
            <path
              d="M0.161,0.163C0.239,0.163 0.289,0.124 0.334,0.017L0.536,-0.465C0.538,-0.471 0.54,-0.478 0.54,-0.484C0.54,-0.505 0.523,-0.522 0.502,-0.522C0.482,-0.522 0.471,-0.509 0.465,-0.493L0.305,-0.086L0.125,-0.492C0.118,-0.509 0.107,-0.522 0.086,-0.522C0.064,-0.522 0.047,-0.505 0.047,-0.484C0.047,-0.477 0.049,-0.47 0.052,-0.463L0.268,-0.005L0.264,0.003C0.236,0.066 0.208,0.095 0.16,0.095C0.134,0.095 0.119,0.091 0.104,0.086C0.098,0.084 0.092,0.082 0.085,0.082C0.068,0.082 0.052,0.096 0.052,0.115C0.052,0.133 0.064,0.143 0.076,0.148C0.102,0.157 0.127,0.163 0.161,0.163Z"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
          <g transform="matrix(36,0,0,36,131.007,283.098)">
            <path
              d="M0.339,0.01C0.478,0.01 0.578,-0.068 0.578,-0.188L0.578,-0.19C0.578,-0.297 0.506,-0.354 0.35,-0.388C0.196,-0.421 0.16,-0.458 0.16,-0.524L0.16,-0.526C0.16,-0.589 0.218,-0.639 0.309,-0.639C0.374,-0.639 0.431,-0.621 0.49,-0.578C0.497,-0.573 0.504,-0.571 0.513,-0.571C0.534,-0.571 0.551,-0.588 0.551,-0.609C0.551,-0.624 0.542,-0.635 0.534,-0.641C0.47,-0.687 0.403,-0.71 0.311,-0.71C0.178,-0.71 0.081,-0.629 0.081,-0.519L0.081,-0.517C0.081,-0.402 0.155,-0.35 0.316,-0.315C0.463,-0.284 0.498,-0.247 0.498,-0.182L0.498,-0.18C0.498,-0.111 0.436,-0.061 0.342,-0.061C0.253,-0.061 0.187,-0.088 0.121,-0.144C0.116,-0.149 0.107,-0.153 0.097,-0.153C0.076,-0.153 0.059,-0.136 0.059,-0.114C0.059,-0.101 0.065,-0.09 0.074,-0.084C0.152,-0.022 0.237,0.01 0.339,0.01Z"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
          <g transform="matrix(36,0,0,36,154.047,283.098)">
            <path
              d="M0.099,-0.035C0.099,-0.013 0.117,0.005 0.138,0.005C0.16,0.005 0.178,-0.013 0.178,-0.035L0.178,-0.247L0.348,-0.247C0.497,-0.247 0.623,-0.325 0.623,-0.476L0.623,-0.478C0.623,-0.616 0.519,-0.7 0.361,-0.7L0.138,-0.7C0.117,-0.7 0.099,-0.682 0.099,-0.66L0.099,-0.035ZM0.178,-0.319L0.178,-0.627L0.355,-0.627C0.468,-0.627 0.543,-0.575 0.543,-0.475L0.543,-0.473C0.543,-0.381 0.467,-0.319 0.351,-0.319L0.178,-0.319Z"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
          <g transform="matrix(36,0,0,36,178.095,283.098)">
            <path
              d="M0.424,0.012C0.637,0.012 0.782,-0.157 0.782,-0.35L0.782,-0.352C0.782,-0.545 0.639,-0.712 0.426,-0.712C0.213,-0.712 0.068,-0.543 0.068,-0.35L0.068,-0.348C0.068,-0.155 0.211,0.012 0.424,0.012ZM0.426,-0.061C0.266,-0.061 0.15,-0.191 0.15,-0.35L0.15,-0.352C0.15,-0.511 0.264,-0.639 0.424,-0.639C0.584,-0.639 0.7,-0.509 0.7,-0.35L0.7,-0.348C0.7,-0.189 0.586,-0.061 0.426,-0.061Z"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
          <g transform="matrix(36,0,0,36,208.695,283.098)">
            <path
              d="M0.424,0.012C0.637,0.012 0.782,-0.157 0.782,-0.35L0.782,-0.352C0.782,-0.545 0.639,-0.712 0.426,-0.712C0.213,-0.712 0.068,-0.543 0.068,-0.35L0.068,-0.348C0.068,-0.155 0.211,0.012 0.424,0.012ZM0.426,-0.061C0.266,-0.061 0.15,-0.191 0.15,-0.35L0.15,-0.352C0.15,-0.511 0.264,-0.639 0.424,-0.639C0.584,-0.639 0.7,-0.509 0.7,-0.35L0.7,-0.348C0.7,-0.189 0.586,-0.061 0.426,-0.061Z"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
          <g transform="matrix(36,0,0,36,239.295,283.098)">
            <path
              d="M0.099,-0.034C0.099,-0.013 0.116,0.005 0.137,0.005C0.159,0.005 0.176,-0.013 0.176,-0.034L0.176,-0.576L0.61,-0.021C0.622,-0.007 0.634,0.003 0.651,0.003L0.655,0.003C0.676,0.003 0.691,-0.013 0.691,-0.034L0.691,-0.666C0.691,-0.687 0.674,-0.705 0.653,-0.705C0.631,-0.705 0.614,-0.687 0.614,-0.666L0.614,-0.138L0.189,-0.68C0.178,-0.694 0.167,-0.703 0.149,-0.703L0.138,-0.703C0.117,-0.703 0.099,-0.685 0.099,-0.664L0.099,-0.034Z"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
