<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2755 1371"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <g transform="matrix(4.16667,0,0,4.16667,0,0)">
      <path
        d="M22.741,84.741L22.741,84.382C22.741,48.59 49.547,19.273 87.688,19.273C107.118,19.273 119.892,25.029 130.868,33.482C133.204,35.461 135.184,38.158 135.184,42.295C135.184,48.59 130.505,53.086 124.208,53.086C121.151,53.086 118.991,52.187 117.552,50.928C108.916,43.913 99.741,39.597 87.509,39.597C63.58,39.597 45.949,59.561 45.949,84.021L45.949,84.382C45.949,109.021 63.401,128.985 87.509,128.985C101.001,128.985 109.817,124.669 118.991,116.935C120.613,115.496 122.949,114.416 125.647,114.416C131.226,114.416 136.085,119.093 136.085,124.669C136.085,128.085 134.463,130.604 132.486,132.581C120.43,143.192 107.118,149.488 86.788,149.488C50.086,149.488 22.741,120.89 22.741,84.741Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M223.867,100.388L223.867,100.028C223.867,83.302 211.812,69.453 194.722,69.453C177.269,69.453 166.114,83.122 166.114,99.669L166.114,100.028C166.114,116.574 178.349,130.424 195.08,130.424C212.892,130.424 223.867,116.753 223.867,100.388ZM144.345,100.388L144.345,100.028C144.345,72.871 165.935,50.388 195.08,50.388C224.406,50.388 245.636,72.51 245.636,99.669L245.636,100.028C245.636,126.825 224.047,149.488 194.722,149.488C165.755,149.488 144.345,127.365 144.345,100.388Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M343.138,137.437C343.138,143.374 338.46,148.228 332.163,148.228C326.225,148.228 321.187,143.732 321.187,137.437L321.187,132.581C314.89,141.754 306.074,149.308 291.503,149.308C269.913,149.308 257.682,135.099 257.682,112.797L257.682,62.259C257.682,55.964 262.536,51.288 268.475,51.288C274.772,51.288 279.45,55.964 279.45,62.259L279.45,106.323C279.45,121.251 286.824,129.344 299.78,129.344C312.55,129.344 321.187,120.89 321.187,105.964L321.187,62.259C321.187,55.964 325.866,51.288 332.163,51.288C338.101,51.288 343.138,55.964 343.138,62.259L343.138,137.437Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M361.298,62.259C361.298,55.964 365.794,51.288 372.091,51.288C378.388,51.288 383.067,55.964 383.067,62.259L383.067,66.935C389.364,58.122 397.818,50.388 412.751,50.388C434.34,50.388 446.572,64.597 446.572,86.719L446.572,137.437C446.572,143.732 442.076,148.228 435.779,148.228C429.482,148.228 424.804,143.732 424.804,137.437L424.804,93.374C424.804,78.445 417.43,70.173 404.474,70.173C391.7,70.173 383.067,78.806 383.067,93.732L383.067,137.437C383.067,143.732 378.388,148.228 372.091,148.228C366.153,148.228 361.298,143.732 361.298,137.437L361.298,62.259Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M468.331,121.07L468.331,71.072L464.732,71.072C459.515,71.072 455.378,66.935 455.378,61.719C455.378,56.504 459.515,52.367 464.732,52.367L468.331,52.367L468.331,36.179C468.331,30.244 473.01,25.389 479.307,25.389C485.242,25.389 490.1,30.244 490.1,36.179L490.1,52.367L507.193,52.367C512.409,52.367 516.727,56.504 516.727,61.719C516.727,66.935 512.409,71.072 507.193,71.072L490.1,71.072L490.1,117.654C490.1,126.108 494.42,129.524 501.793,129.524C504.491,129.524 506.472,128.985 507.193,128.985C512.047,128.985 516.367,132.941 516.367,137.977C516.367,142.114 513.669,145.171 510.609,146.431C505.751,148.049 501.434,148.948 495.679,148.948C479.666,148.948 468.331,141.935 468.331,121.07Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M22.741,192.999C22.741,186.682 27.612,181.812 33.928,181.812L36.274,181.812C41.144,181.812 44.389,184.337 46.554,187.769L84.807,247.67L123.233,187.581C125.578,183.798 129.009,181.812 133.341,181.812L135.686,181.812C141.995,181.812 146.874,186.682 146.874,192.999L146.874,298.72C146.874,305.037 141.995,309.728 135.686,309.728C129.729,309.728 124.679,304.678 124.679,298.72L124.679,222.95L93.829,269.317C91.484,272.921 88.591,275.095 84.448,275.095C80.296,275.095 77.591,272.921 75.246,269.317L44.569,223.309L44.569,298.908C44.569,305.225 39.878,309.728 33.561,309.728C27.252,309.728 22.741,305.225 22.741,298.908L22.741,192.999Z"
        style="fill:rgb(84,169,218);fill-rule:nonzero;"
      />
      <path
        d="M233.121,254.518C231.682,240.634 223.38,229.438 208.948,229.438C195.415,229.438 186.213,239.547 184.228,254.518L233.121,254.518ZM211.473,310.995C184.048,310.995 162.58,290.965 162.58,261.554L162.58,261.195C162.58,233.77 181.882,211.582 209.128,211.582C239.438,211.582 254.417,236.474 254.417,258.491C254.417,264.807 249.726,268.95 244.128,268.95L184.407,268.95C186.753,284.648 197.581,293.131 211.833,293.131C221.034,293.131 228.07,289.886 234.387,284.836C236.013,283.569 237.272,282.85 239.977,282.85C244.855,282.85 248.639,286.634 248.639,291.684C248.639,294.397 247.381,296.743 245.754,298.361C237.092,306.124 226.452,310.995 211.473,310.995Z"
        style="fill:rgb(84,169,218);fill-rule:nonzero;"
      />
      <path
        d="M288.139,192.632C288.139,186.323 293.009,181.632 299.326,181.632C305.643,181.632 310.334,186.323 310.334,192.632L310.334,298.72C310.334,305.037 305.643,309.728 299.326,309.728C293.009,309.728 288.139,305.037 288.139,298.72L288.139,192.632Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M332.529,223.489C332.529,217.18 337.04,212.489 343.349,212.489C349.666,212.489 354.356,217.18 354.356,223.489L354.356,228.18C360.673,219.338 369.156,211.582 384.127,211.582C405.775,211.582 418.049,225.834 418.049,248.029L418.049,298.908C418.049,305.225 413.538,309.728 407.221,309.728C400.904,309.728 396.213,305.225 396.213,298.908L396.213,254.706C396.213,239.727 388.818,231.424 375.824,231.424C363.019,231.424 354.356,240.086 354.356,255.065L354.356,298.908C354.356,305.225 349.666,309.728 343.349,309.728C337.399,309.728 332.529,305.225 332.529,298.908L332.529,223.489Z"
        style="fill:white;fill-rule:nonzero;"
      />
      <path
        d="M569.798,75.933C569.798,96.654 576.37,107.475 584.687,116.428C590.638,122.92 600.491,130.19 598.372,147.623C598.331,147.963 591.938,208.236 591.938,208.236C589.572,236.119 587.376,267.222 586.016,291.907C586.016,291.933 586.016,291.959 586.014,291.986C586.038,305.002 597.219,310.995 605.367,310.995C613.515,310.995 624.697,305.002 624.719,291.986C624.719,291.959 624.719,291.933 624.717,291.907C623.254,265.333 617.672,201.201 617.312,201.488C617.312,201.488 612.607,150.701 612.534,150.235C609.279,129.425 620.165,122.154 626.047,115.781C634.364,106.888 640.936,96.139 640.936,75.556C640.936,54.973 624.365,19.273 605.367,19.273C586.369,19.273 569.798,54.973 569.798,75.556L569.798,75.933Z"
        style="fill:rgb(83,168,217);fill-rule:nonzero;"
      />
      <path
        d="M550.905,235.997L513.955,235.997L513.955,199.047C513.955,193.487 509.823,189.355 504.263,189.355C498.699,189.355 494.409,193.487 494.409,199.047L494.409,235.997L457.463,235.997C451.899,235.997 447.768,240.287 447.768,245.851C447.768,251.411 451.899,255.543 457.463,255.543L494.409,255.543L494.409,292.489C494.409,298.053 498.699,302.184 504.263,302.184C509.823,302.184 513.955,298.053 513.955,292.489L513.955,255.543L550.905,255.543C556.465,255.543 560.597,251.411 560.597,245.851C560.597,240.287 556.465,235.997 550.905,235.997Z"
        style="fill:rgb(247,141,46);fill-rule:nonzero;"
      />
    </g>
  </svg>
</template>
