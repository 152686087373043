import axios, { AxiosError } from "axios"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import humps from "humps"

import { CONFIG } from "@/config"
import {
  IAnemiaAssessment,
  IBestPracticeAssessment,
  IChild,
  IDevelopmentalScreening,
  IFeedingScreening,
  IGrowthAssessment,
  ISiteVisitReport,
} from "@/db"
import { parseLinkHeader } from '@/utils/ParseLinks'


export const DEFAULT_PAGE_SIZE = 25

dayjs.extend(utc)

export class ApiError extends Error {
  constructor(name: string, message?: string, data?: object) {
    super(message)
    this.name = name
    this["data"] = data
  }
}

// These are the fields related to children/site membership that need to be converted to/from Date representations.
const childDateFields = [
  "dob",
  "dateOfDeath",
  "nextGrowthAssessmentDate",
  "nextMealtimeAssessmentDate",
  "nextBestPracticesAssessmentDate",
  "nextEarlyidAssessmentDate",
  "nextAnemiaAssessmentDate",
  "firstAssessmentDate",
  "dateOfAdmission",
  "dateOfDischarge",
]

// ==============================
// = Transformers and utilities =
// ==============================

// Purge any falsy values from an object that will be converted to a query string
// (Axios won't pass on null values, but would pass false or 0)
function purgeFalsyUrlParams(params: object) {
  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      delete params[key]
    }
  }
  return params
}

// Given an array of field names, return a function that accepts the usual
// Axios transformRequest function args and converts specified date fields to
// a format Django is expecting.
function getDateRequestTransformer(fieldNames: Array<string>) {
  return function(data: object) {
    for (const field of fieldNames) {
      if (data[field]) {
        data[field] = dayjs(data[field]).format("YYYY-MM-DD")
      }
    }
    return data
  }
}

// Given an array of field names, return a function that accepts the usual
// Axios transformRequest function args and converts specified date fields from
// local time to UTC, and in a format Django is expecting.
function getDateTimeRequestTransformer(fieldNames: Array<string>) {
  return function(data: object) {
    fieldNames.forEach(field => {
      if (data[field]) {
        data[field] = dayjs.utc(data[field]).format()
      }
    })
    return data
  }
}

function massageNotes(data: { notes?: Array<object> }) {
  if (data.notes?.length) {
    data.notes.forEach((noteObj: { account?: string, id?: number }) => {
      // (delete doesn't care if these props don't exist.)
      delete noteObj.account
      delete noteObj.id
    })
  }
  return data
}

// Given an array of field names, return a function that accepts the usual
// Axios transformResponse function args and converts specified date fields to
// JS objects
function getDateResponseTransformer(fieldNames: Array<string>) {
  return function(data: Array<object>) {
    data.forEach(item => {
      fieldNames.forEach(field => {
        if (item[field]) {
          item[field] = new Date(item[field])
        }
      })
    })
    return data
  }
}

// Given an array of field names, return a function that accepts the usual
// Axios transformResponse function args and converts specified datetime fields–
// in UTC!–to JS objects in device's timezone.
function getDateTimeResponseTransformer(fieldNames: Array<string>) {
  return function(data: Array<object>) {
    data.forEach(item => {
      fieldNames.forEach(field => {
        if (item[field]) {
          item[field] = new Date(item[field])
        }
      })
    })
    return data
  }
}

// Backend has its own id field, which in this app we call the "cmiId"–rename it.
function mungeIds(data: Array<object> | { id: number, cmiId?: number }) {
  function strip(item: { id: number, cmiId?: number }) {
    item.cmiId = item.id
    delete item.id
  }
  if (data instanceof Array) {
    data.forEach(strip)
  }
  else {
    strip(data)
  }
  return data
}

// This is very brittle, since it relies on our knowledge that axios.defaults.transformResponse
// and axios.defaults.transformRequest are single-element arrays–at least at the moment. But using
// the spread syntax was offensive to TypeScript.
const baseResponseTransformers = [axios.defaults.transformResponse[0], (data) => humps.camelizeKeys(data)]
const baseRequestTransformers = [(data) => humps.decamelizeKeys(data), axios.defaults.transformRequest[0]]

export const childrenResponseTransformers = [
  ...baseResponseTransformers,
  getDateResponseTransformer(childDateFields),
  mungeIds,
]

export const assessmentResponseTransformers = [
  ...baseResponseTransformers,
  getDateResponseTransformer(["dateOfAssessment", "dueDate", "testDate"]),
  getDateTimeResponseTransformer(["dateCreated"]),
  mungeIds,
]

// Code to translate from Python <=> JS conventions from
// https://medium.com/@mikerelated/making-axios-play-nicely-with-a-rails-api-949782fccd2e
const api = axios.create({
  baseURL: CONFIG.API_URL,
  timeout: 10000,
  withXSRFToken: true,
  withCredentials: true,
  // These are the Django defaults
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  transformResponse: baseResponseTransformers,
  transformRequest: baseRequestTransformers,
})

function handleGenericAPIError(error: AxiosError) {
  // Server errors
  if (error.response) {
    // First check to see if we're idiosyncratically returning a 409 CONFLICT.
    // Our API uses that to ensure indicate a redundant POST operation; we want
    // POSTs to be idempotent. If so, we know that we've got the returned instance
    // attached as a "data" property.
    if (error.response.status === 409) {
      return error.response.data["data"]
    }
    if (error.response.status === 400) {
      throw new ApiError("BAD_REQUEST", null, error.response.data)
    }
    if (error.response.status === 401) {
      throw new ApiError("LOGIN_REQUIRED")
    }
    if (error.response.status === 403) {
      throw new ApiError("FORBIDDEN", null, error.response.data)
    }
    if (error.response.status === 404) {
      throw new ApiError("NOT_FOUND", null, error.response.data)
    }
    if (error.response.status === 500) {
      throw new ApiError("SERVER_ERROR", null, error.response.data)
    }
  }
  // Connectivity errors
  else if (error.request) {
    console.log(error.code)
    console.log(error.request)
    const connectivityErrors = [
      "ERR_INTERNET_DISCONNECTED",
      "ECONNABORTED",
      "ERR_NETWORK", // Can happen when the server is down OR when device is set to be offline.
    ]
    if (connectivityErrors.includes(error.code)) {
      throw new ApiError("CONNECTIVITY_REQUIRED", error.code)
    }
    const serverConnectionErrors = [
      "ERR_CONNECTION_REFUSED",
    ]
    if (serverConnectionErrors.includes(error.code)) {
      throw new ApiError("SERVER_DOWN", error.code)
    }
  }
  console.log(error)
  throw new ApiError("UNKNOWN_ERROR", null, error)
}

function getPaginatedUrlQueryString(siteId: number, urlParams: { discharged?: boolean, asOf?: string }) {
  urlParams["pageSize"] = DEFAULT_PAGE_SIZE.toString()
  urlParams = purgeFalsyUrlParams(urlParams)
  urlParams = humps.decamelizeKeys(urlParams)
  // https://stackoverflow.com/a/53171438/697143
  return new URLSearchParams(urlParams as Record<string, string>).toString()
}

export function getPaginatedChildrenForSiteUrl(siteId: number, urlParams: { discharged?: boolean, asOf?: string }) {
  const qs = getPaginatedUrlQueryString(siteId, urlParams)
  return `/sites/${siteId}/children/?${qs}`
}

export function getPaginatedGrowthAssessmentsForSiteUrl(siteId: number, urlParams: { discharged?: boolean, asOf?: string }) {
  const qs = getPaginatedUrlQueryString(siteId, urlParams)
  return `/sites/${siteId}/growth-assessments/?${qs}`
}

export function getPaginatedBestPracticeAssessmentsForSiteUrl(siteId: number, urlParams: { discharged?: boolean, asOf?: string }) {
  const qs = getPaginatedUrlQueryString(siteId, urlParams)
  return `/sites/${siteId}/best-practices/?${qs}`
}

export function getPaginatedFeedingScreeningsForSiteUrl(siteId: number, urlParams: { discharged?: boolean, asOf?: string }) {
  const qs = getPaginatedUrlQueryString(siteId, urlParams)
  return `/sites/${siteId}/feeding-screenings/?${qs}`
}

export function getPaginatedAnemiaAssessmentsForSiteUrl(siteId: number, urlParams: { discharged?: boolean, asOf?: string }) {
  const qs = getPaginatedUrlQueryString(siteId, urlParams)
  return `/sites/${siteId}/anemia-assessments/?${qs}`
}

export function getPaginatedDevelopmentalScreeningsForSiteUrl(siteId: number, urlParams: { discharged?: boolean, asOf?: string }) {
  const qs = getPaginatedUrlQueryString(siteId, urlParams)
  return `/sites/${siteId}/developmental-screenings/?${qs}`
}

export function getPaginatedSiteVisitReportsForSiteUrl(siteId: number, urlParams: { asOf?: string }) {
  const qs = getPaginatedUrlQueryString(siteId, urlParams)
  return `/sites/${siteId}/visits/?${qs}`
}

// A generator that accepts the initial url (of a paginated API endpoint) to call and a
// function to call on the payload of the API response. Yields the number of
// results just retrieved before hitting the "next" url derived from the response header.
export async function* stepThroughPaginatedAPI(startUrl: string, processor: (data: Array<unknown>) => void, responseTransformers = undefined) {
  let url = startUrl
  const config = responseTransformers ? { transformResponse: responseTransformers } : {}
  while (url) {
    const response = await api.get(url, config)
    const links = parseLinkHeader(response.headers["link"])
    url = links?.next?.url
    processor(response.data)
    yield response.data.length
  }
}

// ============
// = Accounts =
// ============

/**
 * Given a username and password, attempt to POST it to the server. If it errors, interpret the
 * error with a nice user-facing message and rethrow it.
 * @args {username}     username
 * @args {password}     password
 */
export async function login(username: string, password: string) {
  const url = "/login/"
  await api
    .post(url, { username, password })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.code === "ECONNABORTED" || error.code === "ERR_NETWORK") {
        error.userMessage = "You appear to be offline. Please try again later."
      }
      else if (error.response.status === 500) {
        error.userMessage = "There was an error processing your login. Please try again later."
      }
      else if (error.response.status === 400) {
        error.userMessage = "Your username and password didn't match. Please try again."
      }
      throw error
    })
}

/**
 * Log out. Returns response, which shouldn't be too meaningful. Throws the usual errors.
 */
export async function logout() {
  const url = "/logout/"
  return await api.post(url).catch(handleGenericAPIError)
}

export async function sendResetPasswordRequest(email: string) {
  const url = "/password-reset/"
  return await api.post(url, { email }).catch(handleGenericAPIError)
}

export async function finishPasswordReset({ token, password }: { token: string, password: string }) {
  const url = "/password-reset/confirm/"
  return await api.post(url, { token, password }).catch(handleGenericAPIError)
}

export async function getOwnAccount() {
  const config = {
    method: "get",
    url: `/account/`,
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function getAccountByCmiId(siteId: number, accountId: number) {
  const config = {
    method: "get",
    url: `sites/${siteId}/accounts/${accountId}/`,
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function updateOwnAccount(data: object) {
  const config = {
    method: "put",
    url: `/account/`,
    data: data,
  }
  return await api(config).catch(handleGenericAPIError)
}

export async function updateAccountByCmiId(siteId: number, accountId: number, data: object) {
  const config = {
    method: "put",
    url: `sites/${siteId}/accounts/${accountId}/`,
    data: data,
  }
  return await api(config).catch(handleGenericAPIError)
}

export async function changeOwnPassword(data: object) {
  const config = {
    method: "put",
    url: "/update-password/",
    data,
  }
  return await api(config).catch(handleGenericAPIError)
}

export async function changeOthersPassword(siteId: number, accountId: number, data: object) {
  const config = {
    method: "put",
    url: `sites/${siteId}/accounts/${accountId}/update-password/`,
    data,
  }
  return await api(config).catch(handleGenericAPIError)
}

// ==============
// = Agreements =
// ==============

/**
 * Get list of pending agreement document data. Data structure should be an array of
 * document objects (id, title, file (url)).
 */
export async function getAgreementDocuments() {
  const url = "/agreements/"
  return await api.get(url)
    .then(response => response.data)
    .catch(handleGenericAPIError)
}

/**
 * Agree to specific documents (by id). Returns response data, which should
 * include only hasOutstandingAgreements (boolean)
 */
export async function uploadAgreements(data: object) {
  const url = "/agree/"
  return await api.post(url, data)
    .then(response => response.data)
    .catch(handleGenericAPIError)
}

// ============
// = Projects =
// ============

/**
 * Return an object with projects (and their associatedSites) and (unassociated) sites
 * this person has permissions to. If there are issues, throw an error object.
 */
export async function getProjectsAndSites(inactive = false) {
  const url = "/projects-and-sites/"

  const params = inactive ? { inactive: true } : {}

  return await api
    .get(url, { params })
    .then((response) => {
      // Multiple sets of IDs to munge
      const data = response.data
      // Munge project ids
      data.projects = mungeIds(data.projects)
      // Munge their associated site ids
      for (let i = 0; i < data.projects.length; i++) {
        data.projects[i].associatedSites = mungeIds(data.projects[i].associatedSites)
      }
      // Munge unassociated sites' ids
      data.sites = mungeIds(data.sites)
      return data
    })
    .catch(handleGenericAPIError)
}

export async function getProject(cmiId: number) {
  const config = {
    method: "get",
    url: `projects/${cmiId}/`,
    transformResponse: [...baseResponseTransformers, mungeIds],
  }
  return await api(config)
    .then(response => response.data)
    .catch(handleGenericAPIError)
}


/**  * Given an object with project data, attempt to upload it to the server. Works for  * creating new or updating existing objects.  * If successful, return the response data.  * If unsuccessful, throw the usual exceptions.  * @args {object}  project - project data with field names correspondin
 *   to what the API expect
 * @return [Object]   response dat
 */
export async function uploadProject(project) {
  const copiedObj = Object.assign({}, project)
  const cmiId = copiedObj.cmiId
  delete copiedObj.cmiId
  const config = {
    data: copiedObj,
    method: null,
    url: null,
  }

  // PUT or POST?
  if (cmiId) {
    config.method = "put"
    config.url = `/projects/${cmiId}/`
  } else {
    config.method = "post"
    config.url = "/projects/"
  }

  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function getProjectAccountList(projectId: number) {
  const config = {
    method: "get",
    url: `/projects/${projectId}/accounts/`,
  }
  return await api(config)
    .then(response => response.data)
    .catch(handleGenericAPIError)
}

export async function updateAccountRoleForProject(accountId: number, projectId: number, role: string) {
  const config = {
    method: "put",
    url: `projects/${projectId}/accounts/${accountId}/affiliation/`,
    data: { role },
  }
  return await api(config).catch(handleGenericAPIError)
}

export async function removeAccountRoleFromProject(accountId: number, projectId: number, retainSitePermissions: boolean) {
  const config = {
    method: "delete",
    url: `projects/${projectId}/accounts/${accountId}/affiliation/`,
  }
  if (retainSitePermissions) {
    config.url += "keep-sites/"
  }
  return await api(config).catch(handleGenericAPIError)
}

export async function searchAccountsToLinkForProject(projectCmiId: number, token: string) {
  const config = {
    method: "get",
    url: `/projects/${projectCmiId}/accounts-to-link/`,
    params: { token },
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function linkAccountToProject(accountId: number, projectId: number, role: string) {
  const config = {
    method: "post",
    url: `projects/${projectId}/accounts/${accountId}/affiliation/`,
    data: { role },
  }
  return await api(config).catch(handleGenericAPIError)
}

export async function createProjectAccount(projectId: number, account: object) {
  const config = {
    method: "post",
    url: `projects/${projectId}/accounts/`,
    data: account,
  }
  return await api(config).catch(handleGenericAPIError)
}

// =========
// = Sites =
// =========

export async function getSite(cmiId: number) {
  const config = {
    method: "get",
    url: `sites/${cmiId}/`,
    transformResponse: [...baseResponseTransformers, mungeIds],
  }
  return await api(config)
    .then(response => response.data)
    .catch(handleGenericAPIError)
}

/*
 * Given an object with site data attempt to upload it to the server
 * Infers whether to create or update
 * If successful, return the response data
 * If unsuccessful, throw the usual exceptions
 * @args {object}  site - site data with field names correspondin
 *   to what the API expects. Requires either a cmiId field (update) or projectCmiId field (create
 * @args {boolean}  isSettings - true only if the changes should be PUT to a separate API endpoin
 *   for changes to the site's settings (which modules are enabled)
 * @return [Object]   response dat
 */
export async function uploadSite(site: { cmiId?: number, projectCmiId?: number }, isSettings = false) {
  const config = {
    data: site,
    method: null,
    url: null,
    transformResponse: [...baseResponseTransformers, mungeIds],
  }

  // PUT or POST?
  if (isSettings) {
    config.method = "put"
    config.url = `/sites/${site.cmiId}/settings/`
  }
  else if (site.cmiId) {
    config.method = "put"
    config.url = `/sites/${site.cmiId}/`
  }
  else {
    config.method = "post"
    config.url = `/projects/${site.projectCmiId}/sites/`
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

// ============
// = Children =
// ============

/*
 * Given an object with child data attempt to upload it to the server
 * If successful, return the response data.
 * If unsuccessful, throw the usual exceptions.
 * child data with field names corresponding to what the API expects
 * If patch, then expecting only child-specific data (no membership details).
 */
export async function uploadChild(child, patch = true) {
  const copiedChild = Object.assign({}, child)
  const siteId = copiedChild.siteId
  delete copiedChild.siteId
  const config = {
    data: copiedChild,
    method: null,
    url: null,
    transformRequest: [getDateRequestTransformer(childDateFields), ...baseRequestTransformers],
  }

  // PUT or POST?
  if (copiedChild.cmiId) {
    config.method = patch ? "patch" : "put"
    config.url = `/sites/${siteId}/children/${copiedChild.cmiId}/`
  } else {
    config.method = "post"
    config.url = `/sites/${siteId}/children/`
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function dischargeChild(child: IChild, siteCmiId: number) {
  const fieldNames = [
    "dateOfDeath",
    "causeOfDeath",
    "otherCauseOfDeath",
    "dateOfDischarge",
    "reasonForDischarge",
    "otherReasonForDischarge",
  ]
  const data = Object.fromEntries(fieldNames.map(f => [f, child[f]]))
  const config = {
    data,
    method: "put",
    url: `/sites/${siteCmiId}/children/${child.cmiId}/membership/`,
    transformRequest: [getDateRequestTransformer(childDateFields), ...baseRequestTransformers],
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function transferChild(child: IChild, oldSiteCmiId: number, newSiteCmiId: number) {
  const fieldNames = [
    "dateOfDischarge",
    "reasonForDischarge",
  ]
  const data = Object.fromEntries(fieldNames.map(f => [f, child[f]]))
  data["transferSite"] = newSiteCmiId
  const config = {
    data,
    method: "put",
    url: `/sites/${oldSiteCmiId}/children/${child.cmiId}/membership/`,
    transformRequest: [getDateRequestTransformer(childDateFields), ...baseRequestTransformers],
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function getChildrenForSite(siteId: number, params?: { asOf?: Date, page?: number, discharged?: boolean, all?: boolean, pageSize?: number, count?: boolean }) {
  let wantsCount = false
  if (params.count) {
    params.pageSize = 1
    wantsCount = true
    delete params.count
  }
  params = purgeFalsyUrlParams(params)
  params = humps.decamelizeKeys(params)
  const config = {
    params,
    transformResponse: childrenResponseTransformers,
  }
  return await api
    .get(`/sites/${siteId}/children/`, config)
    .then((response) => {
      if (wantsCount) {
        return { count: parseInt(response.headers["x-total-count"]) }
      }
      else {
        const { data } = response
        data.forEach(child => child.siteId = siteId)
        return data
      }
    })
    .catch(handleGenericAPIError)
}

// =================
// = Training Mode =
// =================

export async function getTrainingModePurgeList(siteId: number) {
  const config = {
    method: "get",
    url: `/sites/${siteId}/training-mode/`,
  }
  return await api(config)
    .then(response => response.data)
    .catch(handleGenericAPIError)
}

export async function disableTrainingMode(siteId: number) {
  const config = {
    method: "put",
    url: `/sites/${siteId}/training-mode/`,
    data: { inTrainingMode: false },
  }
  return await api(config)
    .then(response => response.data)
    .catch(handleGenericAPIError)
}

// ================================
// = Account management for sites =
// ================================

export async function getSiteAccountList(siteId: number) {
  const config = {
    method: "get",
    url: `/sites/${siteId}/accounts/`,
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function searchAccountsToLinkForSite(siteCmiId: number, token: string) {
  const config = {
    method: "get",
    url: `/sites/${siteCmiId}/accounts-to-link/`,
    params: { token },
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function updateAccountRoleAtSite(accountId: number, siteId: number, role: string) {
  const config = {
    method: "put",
    url: `sites/${siteId}/accounts/${accountId}/affiliation/`,
    data: { role },
  }
  return await api(config).catch(handleGenericAPIError)
}

export async function removeAccountRoleFromSite(accountId: number, siteId: number) {
  const config = {
    method: "delete",
    url: `sites/${siteId}/accounts/${accountId}/affiliation/`,
  }
  return await api(config).catch(handleGenericAPIError)
}

export async function linkAccountToSite(accountId: number, siteId: number, role: string) {
  const config = {
    method: "post",
    url: `sites/${siteId}/accounts/${accountId}/affiliation/`,
    data: { role },
  }
  return await api(config).catch(handleGenericAPIError)
}

export async function createSiteAccount(siteId: number, account: object) {
  const config = {
    method: "post",
    url: `sites/${siteId}/accounts/`,
    data: account,
  }
  return await api(config).catch(handleGenericAPIError)
}

interface getAssessmentsForSiteParams {
  asOf?: Date
  page?: number
  discharged?: boolean
  all?: boolean
  pageSize?: number
  count?: boolean
}

// Helper function to handle the heavy lifting for the get*ForSite functions
async function getAssessmentsForSite(urlFragment: string, siteId: number, params?: getAssessmentsForSiteParams) {
  let wantsCount = false
  if (params) {
    if (params.count) {
      wantsCount = true
      params["pageSize"] = 1
      delete params.count
    }
    // Purge any falsy values (Axios won't pass on null values, but would pass false or 0)
    for (const [key, value] of Object.entries(params)) {
      if (!value) {
        delete params[key]
      }
    }
  }
  params = humps.decamelizeKeys(params)
  const config = {
    params,
    transformResponse: assessmentResponseTransformers,
  }
  return await api
    .get(`/sites/${siteId}/${urlFragment}/`, config)
    .then(response => wantsCount ? { count: parseInt(response.headers["x-total-count"]) } : response.data)
    .catch(handleGenericAPIError)
}

// ======================
// = Growth assessments =
// ======================

export async function getGrowthAssessmentsForSite(siteId: number, params?: getAssessmentsForSiteParams) {
  return await getAssessmentsForSite("growth-assessments", siteId, params)
}

export async function getGrowthAssessmentsForChild(childId: number) {
  const config = {
    transformResponse: assessmentResponseTransformers,
  }
  return await api
    .get(`/children/${childId}/growth/`, config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

/*
 * Given an object with growth assessment data, attempt to upload it to the server. Note this function
 * assumes that the childId supplied references a CMI id, not an internal one.
 * If successful, return the response data.
 * If unsuccessful, throw the usual exceptions.
 * @args {object}   growthAssessment - growth assessment data with field names corresponding
 *   to what the API expect.
 * @return [Object] response data
 */
export async function uploadGrowthAssessment(growthAssessment: IGrowthAssessment, justNotes = false) {
  const config = {
    data: justNotes ? { notes: growthAssessment.notes } : growthAssessment,
    method: null,
    url: null,
    transformRequest: [
      getDateRequestTransformer(["dateOfAssessment", "dueDate"]),
      getDateTimeRequestTransformer(["dateCreated"]),
      massageNotes,
      ...baseRequestTransformers,
    ],
  }

  // PATCH, PUT, or POST?
  if (justNotes) {
    config.method = "patch"
    config.url = `children/${growthAssessment.childId}/growth/${growthAssessment.cmiId}/`
  }
  else if (growthAssessment.cmiId) {
    config.method = "put"
    config.url = `children/${growthAssessment.childId}/growth/${growthAssessment.cmiId}/`
  }
  else {
    config.method = "post"
    config.url = `children/${growthAssessment.childId}/growth/`
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}


// ======================
// = Anemia assessments =
// ======================

export async function getAnemiaAssessmentsForSite(siteId: number, params?: { asOf?: Date, page?: number, discharged?: boolean, all?: boolean, pageSize?: number, count?: boolean }) {
  return await getAssessmentsForSite("anemia-assessments", siteId, params)
}

export async function getAnemiaAssessmentsForChild(childId: number) {
  const config = {
    transformResponse: assessmentResponseTransformers,
  }
  return await api
    .get(`/children/${childId}/anemia/`, config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

/*
 * Given an object with anemia assessment data, attempt to upload it to the server. Note this function
 * assumes that the childId supplied references a CMI id, not an internal one.
 * If successful, return the response data.
 * If unsuccessful, throw the usual exceptions.
 * @args {object}   anemiaAssessment - assessment data with field names corresponding
 *   to what the API expects.
 * @return [Object] response data
 */
export async function uploadAnemiaAssessment(anemiaAssessment: IAnemiaAssessment, justNotes = false) {
  const config = {
    data: justNotes ? { notes: anemiaAssessment.notes } : anemiaAssessment,
    method: null,
    url: null,
    transformRequest: [
      getDateRequestTransformer(["dateOfAssessment", "dueDate", "testDate"]),
      getDateTimeRequestTransformer(["dateCreated"]),
      massageNotes,
      ...baseRequestTransformers,
    ],
  }

  // PATCH, PUT, or POST?
  if (justNotes) {
    config.method = "patch"
    config.url = `children/${anemiaAssessment.childId}/anemia/${anemiaAssessment.cmiId}/`
  }
  else if (anemiaAssessment.cmiId) {
    config.method = "put"
    config.url = `children/${anemiaAssessment.childId}/anemia/${anemiaAssessment.cmiId}/`
  }
  else {
    config.method = "post"
    config.url = `children/${anemiaAssessment.childId}/anemia/`
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}


// ============================
// = Developmental screenings =
// ============================
export async function getDevelopmentalScreeningsForSite(siteId: number, params?: { asOf?: Date, page?: number, discharged?: boolean, all?: boolean, pageSize?: number, count?: boolean }) {
  return await getAssessmentsForSite("developmental-screenings", siteId, params)
}

export async function getDevelopmentalScreeningsForChild(childId: number) {
  const config = {
    transformResponse: assessmentResponseTransformers,
  }
  return await api
    .get(`/children/${childId}/developmental-screenings/`, config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

/*
 * Given an object with developmental screening data, attempt to upload it to the server. Note this function
 * assumes that the childId supplied references a CMI id, not an internal one. Either updates an existing one
 * or posts a new one, depending on if cmiId is set.
 * If successful, return the response data.
 * If unsuccessful, throw the usual exceptions.
 * @args {object}   developmentalScreening - developmental screening data with field names corresponding
 *   to what the API expect, including a field for modelName
 * @return [Object] response data
 */
export async function uploadDevelopmentalScreening(developmentalScreening: IDevelopmentalScreening, justNotes = false) {
  const config = {
    data: justNotes ? { notes: developmentalScreening.notes } : developmentalScreening,
    method: null,
    url: null,
    transformRequest: [
      getDateRequestTransformer(["dateOfAssessment", "dueDate"]),
      getDateTimeRequestTransformer(["dateCreated"]),
      massageNotes,
      ...baseRequestTransformers,
    ],
  }

  // PATCH, PUT, or POST?
  if (justNotes) {
    config.method = "patch"
    config.url = `children/${developmentalScreening.childId}/developmental-screenings/${developmentalScreening.cmiId}/?model_name=${developmentalScreening.modelName}`
  }
  else if (developmentalScreening.cmiId) {
    config.method = "put"
    config.url = `children/${developmentalScreening.childId}/developmental-screenings/${developmentalScreening.cmiId}/?model_name=${developmentalScreening.modelName}`
  }
  else {
    config.method = "post"
    config.url = `children/${developmentalScreening.childId}/developmental-screenings/new/?model_name=${developmentalScreening.modelName}`
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

// ======================
// = Feeding Screenings =
// ======================
/*
 * Given an object with feeding screening data, attempt to upload it to the server. Note this function
 * assumes that the childId supplied references a CMI id, not an internal one.
 * If successful, return the response data.
 * If unsuccessful, throw the usual exceptions.
 * @args {object}   feedingScreening - data with field names corresponding
 *   to what the API expect.
 * @return [Object] response data
 */
export async function uploadFeedingScreening(feedingScreening: IFeedingScreening, justNotes = false) {
  const config = {
    data: justNotes ? { notes: feedingScreening.notes } : feedingScreening,
    method: null,
    url: null,
    transformRequest: [
      getDateRequestTransformer(["dateOfAssessment", "dueDate"]),
      getDateTimeRequestTransformer(["dateCreated"]),
      massageNotes,
      ...baseRequestTransformers,
    ],
  }

  // PATCH, PUT, or POST?
  if (justNotes) {
    config.method = "patch"
    config.url = `children/${feedingScreening.childId}/feeding-screening/${feedingScreening.cmiId}/`
  }
  else if (feedingScreening.cmiId) {
    config.method = "put"
    config.url = `children/${feedingScreening.childId}/feeding-screening/${feedingScreening.cmiId}/`
  }
  else {
    config.method = "post"
    config.url = `children/${feedingScreening.childId}/feeding-screening/`
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function getFeedingScreeningsForSite(siteId: number, params?: { asOf?: Date, page?: number, discharged?: boolean, all?: boolean, pageSize?: number, count?: boolean }) {
  return await getAssessmentsForSite("feeding-screenings", siteId, params)
}

export async function getFeedingScreeningsForChild(childId: number) {
  const config = {
    transformResponse: assessmentResponseTransformers,
  }
  return await api
    .get(`/children/${childId}/feeding-screening/`, config)
    .then(response => response.data)
    .catch(handleGenericAPIError)
}

// ======================
// = Best Practices =
// ======================
/*
 * Given an object with best practices data, attempt to upload it to the server. Note this function
 * assumes that the childId supplied references a CMI id, not an internal one.
 * If successful, return the response data.
 * If unsuccessful, throw the usual exceptions.
 * @args {object}   assessment - data with field names corresponding to what the API expect.
 * @return [Object] response data
 */
export async function uploadBestPracticeAssessment(assessment: IBestPracticeAssessment, justNotes = false) {
  const config = {
    data: justNotes ? { notes: assessment.notes } : assessment,
    method: null,
    url: null,
    transformRequest: [
      getDateRequestTransformer(["dateOfAssessment", "dueDate"]),
      getDateTimeRequestTransformer(["dateCreated"]),
      massageNotes,
      ...baseRequestTransformers,
    ],
  }

  // PATCH, PUT, or POST?
  if (justNotes) {
    config.method = "patch"
    config.url = `children/${assessment.childId}/best-practices/${assessment.cmiId}/`
  }
  else if (assessment.cmiId) {
    config.method = "put"
    config.url = `children/${assessment.childId}/best-practices/${assessment.cmiId}/`
  }
  else {
    config.method = "post"
    config.url = `children/${assessment.childId}/best-practices/`
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

export async function getBestPracticeAssessmentsForSite(siteId: number, params?: { asOf?: Date, page?: number, discharged?: boolean, all?: boolean, pageSize?: number, count?: boolean }) {
  return await getAssessmentsForSite("best-practices", siteId, params)
}

export async function getBestPracticeAssessmentsForChild(childId: number) {
  const config = {
    transformResponse: assessmentResponseTransformers,
  }
  return await api
    .get(`/children/${childId}/best-practices/`, config)
    .then(response => response.data)
    .catch(handleGenericAPIError)
}

// ======================
// = Site Visit Reports =
// ======================
export const siteVisitReportTransformers = [
  ...baseResponseTransformers,
  getDateResponseTransformer(["dateOfVisit"]),
  mungeIds,
]

export async function getSiteVisitReportsForSite(siteCmiId: number, params?: { asOf?: Date, page?: number, all?: boolean, pageSize?: number, count?: boolean }): Promise<{ count?: number, results?: Array<ISiteVisitReport> }> {
  let wantsCount = false
  if (params) {
    if (params.count) {
      wantsCount = true
      params["pageSize"] = 1
      delete params.count
    }
    // Purge any falsy values (Axios won't pass on null values, but would pass false or 0)
    for (const [key, value] of Object.entries(params)) {
      if (!value) {
        delete params[key]
      }
    }
  }
  params = humps.decamelizeKeys(params)
  const config = {
    params,
    transformResponse: siteVisitReportTransformers,
  }
  return await api
    .get(`/sites/${siteCmiId}/visits/`, config)
    .then(response => wantsCount ? { count: parseInt(response.headers["x-total-count"]) } : response.data)
    .catch(handleGenericAPIError)
}

export async function uploadSiteVisitReport(siteId: number, report: ISiteVisitReport): Promise<ISiteVisitReport> {
  const config = {
    data: report,
    method: null,
    url: null,
    transformRequest: [getDateRequestTransformer(["dateOfVisit"]), ...baseRequestTransformers],
  }

  // PUT or POST?
  if (report.cmiId) {
    config.method = "put"
    config.url = `/sites/${siteId}/visits/${report.cmiId}/`
  }
  else {
    config.method = "post"
    config.url = `/sites/${siteId}/visits/`
  }
  return await api(config)
    .then((response) => response.data)
    .catch(handleGenericAPIError)
}

// ===========
// = Reports =
// ===========

// Get summary report data at the app (no args), project, or site level.
// Data structure is sprawling!
export async function getDemographicReportData(options?: { projectCmiId?: number, siteCmiId?: number }) {
  let url: string
  const { projectCmiId, siteCmiId } = options || {}
  if (siteCmiId) {
    url = `/sites/${siteCmiId}/reports/`
  }
  else if (projectCmiId) {
    url = `/projects/${projectCmiId}/reports/`
  }
  else {
    url = "/reports/"
  }

  return await api
    .get(url)
    .then(response => response.data)
    .catch(handleGenericAPIError)
}

// Get malnutrition report data at the app (no args), project, or site level.
// Data structure is:
// indicator (e.g. anemia): {
//     label: e.g. "Anemia", (translated!)
//     "stats": [
//  Three of the following objs representing:
//  all at baseline, 2+ assessments baseline, 2+ assess. most recent assessment
//         {
//             "num": 9,
//             "den": 13,
//             "pct": "69.2%"
//         },
export async function getMalnutritionReportData(options: { projectCmiId?: number, siteCmiId?: number }, params = {}) {
  let url: string
  const { projectCmiId, siteCmiId } = options
  if (siteCmiId) {
    url = `/sites/${siteCmiId}/reports/malnutrition/`
  }
  else if (projectCmiId) {
    url = `/projects/${projectCmiId}/reports/malnutrition/`
  }
  else {
    url = "/reports/malnutrition/"
  }
  return await api
    .get(url, { params })
    .then(response => response.data)
    .catch(handleGenericAPIError)
}
