import { createGettext } from "vue3-gettext"

// Make sure that when you add new languages you include their
// paths in manualChunks, since they're only imported dynamically below.
const availableLanguages = {
    en: "English",
    es: "Español",
    fr: "Francais",
    st: "Sesotho",
}
const translations = {
    // This is fake; upon initial load, we set the language and import
    // a real translation file. This prevents us from downloading the
    // English translation if that's not our browser's preferred language.
    en: { en: { stub: "stub" } }
}

export const gettext = createGettext({
    defaultLanguage: "en",
    availableLanguages,
    translations,
})

// Instead of combining all of the translations into a giant object,
// we're setting the translations prop to contain only one language
// at a time. And we're dynamically importing it, too, so users aren't
// saddled with all the translations preemptively.
export async function updateLanguage(lang: string) {
    // Guard here against possible crazy imports
    if (lang in availableLanguages) {
        gettext.translations = await import(`@/locales/translations/${lang}.json`)
        gettext.current = lang
    }
}
